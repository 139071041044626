<template>
    <div id="page-user-list">
           <div class="vx-row">
            <div class="vx-col w-full sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1">
               <vx-card  title="เลือกวันเวลา">
                <label>เริ่มต้น: </label>
                <flat-pickr :config="configdateTimePicker" v-model="before_datetime" placeholder="Date Time" />
                <br>
                <br>
                <label> สิ้นสุด: </label>
                <flat-pickr :config="configdateTimePicker" v-model="after_datetime" placeholder="Date Time" />
                <br>
                <br>
                <vs-button color="primary" @click="callTableRegister ()">ค้นหา</vs-button>
              </vx-card>
            </div>

      <div class="vx-col w-full sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1 mt-6">
        <statistics-card-line hideChart class="mb-base" icon="PlusCircleIcon" :statistic="registerTable.count"
          statisticTitle="จำนวนผู้สมัคร" color="success" />
      </div>

      </div>
      <div class="vx-card p-8">
        <div class="flex flex-wrap items-center">

          <!-- ITEMS PER PAGE -->
          <div class="flex-grow">

            <br>
            <vx-card>
              <div class="vx-card__title">
              <h4>ยูสที่สมัครทั้งหมด</h4>
              <br>
            </div>

              <div class="export-table">
                <vs-table stripe pagination max-items="10" :data="registerTable.table" search>

                  <template slot="thead">
                    <vs-th> ยูสเซอร์เนม</vs-th>
                    <vs-th> วันเวลา </vs-th>

                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                      <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].username}`">{{data[indextr].username}}</router-link></vs-td>
                      <vs-td>{{moment(data[indextr].datetime).format('YYYY-MM-DD HH:mm')}}</vs-td>

                    </vs-tr>

                  </template>

                </vs-table>
              </div>
            </vx-card>

          </div>
        </div>
      </div>
    </div>

  </template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import times from './ReportAff'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import axios from '../../axios'
import moment from 'moment'

// function currencyFormatter (params) {
//   return (params.value).replace(/\d(?=(\d{3})+\.)/g, '$&,')
// }
export default {
  components: {
    vSelect,
    flatPickr,
    times,
    StatisticsCardLine
  },
  data () {
    return {
      winTable:[],
      loseTable:[],
      turnTable:[],
      registerTable:[],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      after_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
      before_datetime: moment.tz('Asia/Bangkok').subtract(1, 'day').format('YYYY-MM-DD HH:mm'),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      memberData: [],
      sum_data: []
    }
  },
  async mounted () {

    await this.callTableRegister()

  },
  methods: {
    async callTableRegister () {
      await axios
        .get(`reporttour/register/pnl/${this.before_datetime}/${this.after_datetime}`)
        .then(response => (this.registerTable = response.data))
    }

  }

}

</script>
